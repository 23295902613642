import React from 'react';
import Link from '../components/Link';
import { css } from '@emotion/core'
import Container from 'components/Container'
import { rhythm } from '../lib/typography'
import { useTheme } from '../components/Theming';
import Layout from '../components/Layout';

// import { Container } from './styles';

function About({ data: { site, allMdx } }) {
  const theme = useTheme()
  return (
    <Layout site={site}>
      <section
        css={css`
          color: ${theme.colors.white};
          width: 100%;
          background: ${theme.colors.primary};
          padding: 20px 0 30px 0;
          display: flex;
        `}
      >
        <Container>
          <h1
            css={css`
              color: ${theme.colors.white};
              position: relative;
              z-index: 5;
              line-height: 1.5;
              margin: 0;
              max-width: ${rhythm(15)};
            `}
          >
            Olá Mundo!
          </h1>
          <div>
            <h2> créditos </h2>
            <ul>
              <li> Baseado no <Link css={css` color: ${theme.colors.white}; `} to="https://github.com/eggheadio/gatsby-starter-egghead-blog">gatsby-starter-egghead-blog</Link> </li>
              <li> Ilustrações providas pelo <Link css={css` color: ${theme.colors.white}; `} to="https://undraw.co/">unDraw</Link>,</li>
            </ul>
            
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`

export default About;